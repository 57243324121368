import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { FilteredListServiceBase } from '../../../classes/filtered-list-service-base';

interface TaskPreviewResponse {
  task: {
    list: {
      output: Array<any>;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class TaskPreviewService extends FilteredListServiceBase<TaskPreviewResponse> {
  static cursor: String = null;
  resultApollo;

  constructor(public apollo: Apollo) {
    super(apollo);
  }

  getListQuery(querySource) {
    return querySource;
  }

  getMutationQuery() {
    return null;
  }

  applyNewData(newResult) {
    return {
      task: {
        list: newResult,
      },
    };
  }
}
