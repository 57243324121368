import { Injectable } from '@angular/core';
import { EmployeeInterface } from './definitions/employee-interface';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { CreateEmployeeInterface } from './definitions/employee-create-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface EmployeesCreateResponse {
  employee: EmployeeInterface;
}

@Injectable()
export class EmployeesCreateService {
  constructor(private apollo: Apollo) {}

  create(
    employee: CreateEmployeeInterface,
    mutation: DocumentNode
  ): Observable<FetchResult<EmployeesCreateResponse>> {
    return this.apollo.mutate<EmployeesCreateResponse>({
      mutation: mutation,
      variables: {
        email: employee.email,
        roles: employee.roles,
        name: employee.name,
        username: employee.username,
        divisions: employee.divisions,
      },
    });
  }
}
