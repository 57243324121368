export const environment = {
  useMocks: false,
  useProdMode: true,
  enableDevMode: true,
  enableConsoleLog: true,
  enableComponentGallery: false,
  featureFlags: {
    harmfulEvents: true,
    assignments: true
  },
  apollo: {
    apiUrl: 'https://preview-front.klobal.cz/graphql',
    subscriptionsUrl: 'wss://preview-front.klobal.cz/subscriptions',
  },
  rest: {
    apiV2Url: "https://preview-front.klobal.cz/api/"
  },
  sentry: {
    allow: true,
    dsn: 'https://4051dda49beee1d8078539cb96febd35@o4506434096201728.ingest.us.sentry.io/4506828862914560',
    allowReplay: false
  },
  routerTracing: false,
  customMasks: {
    decimalMarker: ',',
    thousandSeparator: ' ',
  },
  environment: 'preview',
  appVersion: 'dev-d0090f88ba0b'
};
