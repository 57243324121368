import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskPreviewService } from './task-preview.service';
import { TaskUpdateService } from './update.service';
import { TaskCreateService } from './create.service';
import { AssumeTaskService } from './assume-task.service';
import { TaskByIdService } from './by-id.service';
import { TaskToCalculationService } from './to-calculation.service';
import { TaskToRevisionService } from './to-revision.service';
import { AssumeRevisionService } from './assume-revision.service';
import { CompleteTaskService } from './complete.service';
import { AssignResolverService } from './assign-resolver.service';
import { AssignReviserService } from './assign-reviser.service';
import { ApproveAllFileGroupsService } from './approve-all-file-groups.service';
import { MarkAsRevisedService } from './mark-as-revised.service';
import { RejectFromRevisionService } from './reject-from-revision';
import { TaskRemoveService } from './remove.service';
import { TaskByTokenIdService } from './by-token-id.service';
import { OnlineTaskInspectionDoneService } from './online-task-inspection-done.service';
import { TaskMarkAsNotExecutedService } from './mark-as-not-executed.service';
import { CreateOnlineTaskInspectionService } from './create-online-task-inspection.service';
import { TaskDocumentationCompletedService } from './task-documentation-completed.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    TaskPreviewService,
    TaskUpdateService,
    TaskCreateService,
    AssumeTaskService,
    TaskByIdService,
    TaskByTokenIdService,
    TaskToCalculationService,
    TaskToRevisionService,
    AssumeRevisionService,
    MarkAsRevisedService,
    RejectFromRevisionService,
    CompleteTaskService,
    AssignResolverService,
    AssignReviserService,
    ApproveAllFileGroupsService,
    TaskRemoveService,
    OnlineTaskInspectionDoneService,
    TaskMarkAsNotExecutedService,
    CreateOnlineTaskInspectionService,
    TaskDocumentationCompletedService,
  ],
})
export class TasksModule {}
